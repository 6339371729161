.container {
    display: grid;
    grid-template-areas: "header nav" "aside main" "footer contact";
    grid-template-columns: 44% auto;
    grid-template-rows: 80px 1fr 60px;
    grid-row-gap: 0;
    grid-column-gap: 32px;
    min-height: 100vh;
    background: #fff;
}

header {
    position: relative;
    grid-area: header;
    display: flex;
    justify-content: flex-end;
    .pattern {
        position: absolute;
        left: 0;
        right: 290px;
        height: 70px;
        background: url(/assets/img/pattern.jpg);
        background-size: 70px;
        background-repeat: repeat-x;
        z-index: 5;
    }
    section {
        z-index: 10;
        width: 460px;
        ion-img {
            position: relative;
            max-width: 90px;
            margin-left: 30px;
        }
    }
}

nav {
    grid-area: nav;
    z-index: 10;
    div {
        position: relative;
        z-index: 20;
        width: 560px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        span {
            font-size: 20px;
            color: var(--ion-color-primary-contrast);
            display: flex;
            align-items: center;
            justify-content: center;
            height: 64px;
            cursor: pointer;
            user-select: none;
        }
        span:hover {
            filter: brightness(1.1);
        }
        span:nth-child(1) {
            background: var(--ion-color-primary-tint);
        }
        span:nth-child(2) {
            background: var(--ion-color-primary-shade);
        }
        span:nth-child(3) {
            background: var(--ion-color-primary);
        }
    }
    section {
        z-index: 10;
        display: flex;
        justify-content: flex-end;
        width: 560px;
        column-gap: 8px;
        margin-top: 8px;
        padding: 0 8px;
        ion-img {
            cursor: pointer;
            height: 20px;
            border: 1px solid #eee;
        }
    }
}

aside {
    grid-area: aside;
    display: flex;
    align-items: center;
    ion-img {
        max-width: 460px;
        margin-left: auto;
    }
}

main {
    grid-area: main;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 560px;
    .main-header {
        width: 560px;
        margin-top: 18px;
        h1 {
            margin: 0;
            font-size: 84px;
            font-weight: 900;
            line-height: 1;
            text-align: center;
            overflow: hidden;
            word-wrap: normal;
            text-overflow: ellipsis;
        }
        h3 {
            margin: 0;
            font-size: 34.3px;
            font-weight: 600;
            text-align: center;
        }
    }
    article {
        animation: fade-in .3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
        width: 560px;
        max-height: calc(100vh - 380px);
        min-height: calc(100vh - 380px);
        margin-bottom: 16px;
        overflow-y: auto;
        scrollbar-width: thin;
        scrollbar-color: #a6abe4 #eee;
        h4 {
            margin: 0;
            font-size: 20px;
            position: sticky;
            top: 0;
            background: #fff;
            padding: 12px 0;
            z-index: 10;
            font-weight: 900;
            color: var(--ion-color-primary);
        }
        h4 + p {
            margin-top: -6px;
        }
        p {
            line-height: 1.8;
            strong {
                font-weight: 900;
                color: var(--ion-color-primary);
                cursor: pointer;
            }
        }
        ol,
        ul {
            padding-right: 16px;
        }
        li {
            margin-bottom: 8px;
            line-height: 1.4;
        }
        li h5 {
            font-weight: 600;
            margin-bottom: 4px;
        }
        li p {
            margin: 0 0 3px;
            line-height: 1.4;
        }
    }
    article::-webkit-scrollbar {
        width: 4px;
    }
    article::-webkit-scrollbar-track {
        background: #eee;
    }
    article::-webkit-scrollbar-thumb {
        background-color: #a6abe4;
    }
    .info-buttons {
        width: 560px;
        margin-bottom: 36px;
        display: flex;
        justify-content: space-between;
        ion-button {
            width: 178px;
            --padding-top: 20px;
            --padding-bottom: 20px;
        }
    }
}

footer {
    grid-area: footer;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: var(--ion-color-primary);
    color: var(--ion-color-primary-contrast);
    border-radius: 0 50px 0 0;
    p {
        margin-right: 50px;
    }
}

.contact {
    grid-area: contact;
    display: flex;
    align-items: center;
    > div {
        display: flex;
        justify-content: space-between;
        width: 560px;
        > a {
            color: var(--ion-color-primary);
            display: flex;
            align-items: center;
            font-weight: 600;
            text-decoration: none;
            ion-icon {
                margin-right: 8px;
                font-size: 20px;
            }
        }
    }
}

@media screen and (max-width: 1080px) {
    header {
        .pattern {
            right: 140px;
        }
    }
    .container {
        grid-template-columns: 39% auto;
    }
    aside ion-img {
        max-width: 374px;
    }

    footer p {
        margin-right: 41px;
    }
}

@media screen and (max-width: 1023px) {
    .container {
        grid-template-areas: "header" "nav" "main" "aside" "contact" "footer";
        grid-template-columns: 100%;
        grid-template-rows: 116px 80px auto 1fr 60px 40px;
    }

    header {
        .pattern {
            position: fixed;
            right: 0;
            height: 50px;
            background-size: 50px;
        }
        section {
            width: 100%;
            ion-img {
                max-width: 80px;
                margin: auto;
            }
        }
    }

    nav {
        position: sticky;
        top: 10px;
        div {
            margin: auto;
            span {
                height: 50px;
                margin-bottom: 15px;
            }
        }
        section {
            margin: -158px auto 0;
            position: sticky;
            top: 23px;
        }
    }

    aside {
        ion-img {
            max-width: 280px;
            margin: 40px auto;
        }
    }

    main {
        margin: auto;
        // padding: 16px;
        width: 100vw;
        article {
            max-height: unset;
            min-height: unset;
            h4 {
                position: relative;
                z-index: 1;
                margin-top: 28px;
            }
        }
        .main-header,
        article,
        .info-buttons {
            margin: auto;
        }
    }

    .contact div {
        margin: auto;
    }

    footer {
        width: 560px;
        border-radius: 40px 40px 0 0;
        justify-content: center;
        margin: 0 auto;
        p {
            margin: 0;
        }
    }
}

@media screen and (max-width: 600px) {
    .container {
        grid-template-rows: 116px 80px auto 1fr auto auto;
    }
    nav {
        div {
            width: 100%;
            span {
                font-size: 18px;
            }
        }
        section {
            width: 100%;
            padding: 0 16px;
        }
    }
    aside {
        margin-bottom: 16px;
    }
    main {
        .main-header {
            width: 100%;
            h1 {
                font-size: 52px;
                text-align: center;
                line-height: 1;
                margin-bottom: 4px;
            }
            h3 {
                font-size: 21.2px;
                text-align: center;
                line-height: 1.1;
            }
        }
        article {
            width: 100%;
            padding: 0 16px;
        }
        .info-buttons {
            width: 100%;
            flex-wrap: wrap;
            justify-content: center;
            row-gap: 16px;
            column-gap: 6px;
            margin: 16px 0;
            ion-button {
                width: 144px;
            }
        }
    }

    .contact {
        div {
            margin: 16px;
            flex-wrap: wrap;
            justify-content: center;
            row-gap: 12px;
            column-gap: 16px;
            a {
                ion-icon {
                    margin-right: 6px;
                }
            }
        }
    }

    footer {
        width: 100%;
        border-radius: 0;
        margin: 0;
        p {
            font-size: 14px;
            margin: 16px;
        }
    }
}

@media screen and (max-width: 374px) {
    nav {
        div {
            span {
                font-size: 16px;
            }
        }
    }
    main {
        .main-header {
            h1 {
                font-size: 40px;
                line-height: 1;
            }
            h3 {
                font-size: 16.2px;
            }
        }
    }
    footer {
        p {
            font-size: 13px;
        }
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

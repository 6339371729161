/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "../src/theme/front.scss";

.gps-logo-header {
  max-height: 34px;
  margin-right: 6px;
  position: absolute;
  left: -26px;
  top: -5px;
  pointer-events: none;
}

.ios .gps-logo-header {
  max-height: 32px;
  left: 48px;
  top: 7px;
}

form {
  text-align: center;
}

ion-card-title.ios {
  font-size: 18px;
}

ion-title.md {
  margin-left: 24px;
}

main form ion-item {
  overflow: visible;
  margin-bottom: 10px;
}

div.error-message {
  color: var(--ion-color-danger);
  font-size: 12px;
  width: 100%;
  // position: absolute;
  top: 60px;
}

main .link {
  color: var(--ion-color-primary);
  cursor: pointer;
  font-size: 14px;
  text-decoration: underline;
}

@media screen and (max-width: 1023px) {
  div.error-message {
    top: 74px;
  }
}

.label-floating.sc-ion-label-md-h {
  margin-bottom: 8px;
}
